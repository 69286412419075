import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  dateTimeFormatterstriped,
  toSentenceCase,
} from "../../utils";
import { endpointAdmin,endpointTransaction, endpointManagement } from "../../settings";
import { get, setConfirmDelete, post, del, getWithHeader } from "../slice";

import { deleteFacility } from "../slices/tokenthera";

import Table from "../../components/Table";
import Breadcrumb from "../../components/Breadcrumb";
import Pill from "../../components/Pill";
import { editToken, createMeter, updateMeter } from "../slices/tokenthera";
import { FiPlus } from "react-icons/fi";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Tab from "../../components/Tab";
import Filter from "../../components/Filter";
import Facilities from "../../components/cells/Facilities";
import { FiSearch } from "react-icons/fi";
import Modal from "../../components/Modal";
// import { auth } from "firebase";

const transactionListColumns = [
  {
    Header: "Unit Number",
    accessor: (row) => (
      <b>{row.number}</b>
    ),
  },
  {
    Header: "Meter Number",
    accessor: (row) => <b>{row.meter_number}</b>,
  },
  {
    Header: "Resident Name",
    accessor: (row) => <b>{row.resident_name}</b>,
  },
  {
    Header: "Nominal",
    accessor: (row) => <b>{row.price}</b>,
  },
  {
    Header: "kWh",
    accessor: (row) => <b>{row.kwh}</b>,
  },
  {
    Header: "Status",
    accessor: (row) => (
      row.status === "paid" ?
        <Pill color="warning">
          {toSentenceCase(row.status)}
        </Pill>
        :
      row.status === "inquiry" ?
        <Pill color="secondary">
          {toSentenceCase(row.status)}
        </Pill>
        :
      row.status === "complete" ?
      <Pill color="success">
        {toSentenceCase(row.status)}
      </Pill>
        :
      <Pill color="primary">
        {toSentenceCase(row.status)}
      </Pill>
    ),
  },
  {
    Header: "Payment Method",
    accessor: (row) => <b>{row.payment_method + " - " + row.payment_bank}</b>,
  },
  {
    Header: "Token",
    accessor: (row) => <b>{toSentenceCase(row.token_code)}</b>,
  },
  {
    Header: "Transaction Date",
    accessor: (row) => {
      return (
        <div>
          <div>
            <b>
              {row.created_date ? dateTimeFormatterstriped(row.created_date) : "-"}
            </b>
          </div>
        </div>
      );
    },
  },
];

const unitMeterNumberColumns = [
  {
    Header: "Unit Number",
    accessor: (row) => (
      <b>{row.number}</b>
    ),
  },
  {
    Header: "Meter Number",
    accessor: (row) => <b>{row.meter_number}</b>,
  },
  {
    Header: "Created By",
    accessor: (row) => <b>{row.management_name}</b>,
  },
  {
    Header: "Created Date",
    accessor: (row) => <b>{row.created_at ? dateTimeFormatterstriped(row.created_at) : "-"}</b>,
  },
];

const listBookingStat = [
  { label: "Inquiry", value: "inquiry" },
  { label: "Paid", value: "paid" },
  { label: "Complete", value: "complete" },
  { label: "Failed", value: "failed" },
];

function Component({ view, title = "", pagetitle, canAdd, canDelete }) {
  const [buildingid, setBuildingid] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataMeter, setDataMeter] = useState({ items: [] });
  const [dataBooking, setDataBooking] = useState({ items: [] });
  const [building, setBuilding] = useState("");
  const [type, setType] = useState("");
  const [toggler, setToggler] = useState(false);
  const { role } = useSelector((state) => state.auth);
  const { auth } = useSelector((state) => state);
  const [buildingLabel, setBuildingLabel] = useState("");
  const [buildingList, setBuildingList] = useState("");
  const [unitList, setUnitList] = useState("");
  const [unit, setUnit] = useState("");
  const [number, setNumber] = useState("");
  const [meterNumber, setMeterNumber] = useState("");

  const [modalMeter, setModalMeter] = useState(false);
  const [modalUpdateMeter, setModalUpdateMeter] = useState(false);
  const [modalToken, setModalToken] = useState(false);
  const [tokenCode, setTokenCode] = useState("");
  const [refToken, setRefToken] = useState("");
  const [tokenID, setTokenID] = useState("");

  const [refresh, setRefresh] = useState(false)

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);

  const tabs = ["Transaction List", "Unit Meter Number"];
  const [stat, setStat] = useState("");
  const [statLabel, setStatLabel] = useState("");

  let dispatch = useDispatch();
  let history = useHistory();
  let { url } = useRouteMatch();

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildingList(formatted);
          }
        )
      );
  }, [dispatch, search, limit]);

  useEffect(() => {
    dispatch(
      get(
        endpointManagement +
          "/admin/building-unit-meter/check" +
          "?limit=9999&page=1" +
          "&building_id=36" +
          "&search=",
        (res) => {
          let data = res.data.data.items;

          let formatted = data.map((el) => ({
            label: el.number,
            value: el.id,
          }));

          setUnitList(formatted);
        }
      )
    );
  }, [dispatch]);

  return (
    <>
      <Modal
        isOpen={modalToken}
        toggle={() => {
          setModalToken(false);
        }}
        title="Update Token Code"
        okLabel={"Yes, Submit"}
        onClick={() => {
          setModalToken(false);
          dispatch(editToken(tokenCode, refToken, tokenID));

          setRefresh(!refresh)
          
          setTokenCode("");
          setRefToken("");
        }}
      >
        <Input
          label="Token Code"
          type="text"
          name="token_code"
          inputValue={tokenCode}
          setInputValue={setTokenCode}
        />

        <Input
          label="Reference Code"
          type="text"
          name="ref_code"
          inputValue={refToken}
          setInputValue={setRefToken}
        />
      </Modal>
      
      <Modal
        isOpen={modalUpdateMeter}
        toggle={() => {
          setModalUpdateMeter(false);
          setMeterNumber("");
          setUnit("");
          setNumber("");
        }}
        title="Update Meter Number"
        okLabel={"Yes, Submit"}
        onClick={() => {
          setModalUpdateMeter(false);
          
          dispatch(updateMeter(unit, meterNumber));

          setMeterNumber("");
          setUnit("");
          setNumber("");

          setToggler(!toggler)
        }}
      >
        <Input
          label="Unit Number"
          inputValue={number}
          type="text"
          title="Unit Number"
          disabled
        />

        <Input
          label="Meter Number"
          type="text"
          name="meter_number"
          inputValue={meterNumber}
          setInputValue={setMeterNumber}
        />
      </Modal>

      <Modal
        isOpen={modalMeter}
        toggle={() => {
          setModalMeter(false);
          setMeterNumber("");
          setUnit("");
        }}
        title="Register Meter Number"
        okLabel={"Yes, Submit"}
        onClick={() => {
          setModalMeter(false);
          
          dispatch(createMeter(unit, meterNumber));

          setMeterNumber("");
          setUnit("");
          
          setToggler(!toggler)
        }}
      >
        <Input
          label="Unit Number"
          inputValue={unit}
          type="select"
          options={unitList}
          setInputValue={setUnit}
          title="Unit Number"
        />

        <Input
          label="Meter Number"
          type="text"
          name="meter_number"
          inputValue={meterNumber}
          setInputValue={setMeterNumber}
        />
      </Modal>

      <Breadcrumb title={title} />
      <h2 className="PageTitle">Token Thera</h2>
      <div className="Container">
        <Tab
          labels={tabs}
          setTab={setType}
          activeTab={0}
          contents={[
            <Table
              columns={transactionListColumns}
              data={dataBooking.data?.items || []}
              fetchData={useCallback(
                (page, limit, searchTransaction, sortField, sortType) => {
                  setLoading(true);
                  dispatch(
                    get(
                      endpointTransaction +
                        "/admin/thera/list?status=" +
                        stat +
                        "&search="+
                        searchTransaction+
                        "&building_id=" +
                        building +
                        "&limit=" +
                        limit +
                        "&page=" +
                        (page + 1),

                      (res) => {
                        console.log(JSON.stringify(res.data))
                        setDataBooking(res.data);
                        setLoading(false);
                      }
                    )
                  );
                },
                [dispatch, building,search,stat,refresh]
              )}
              onClickEdit={
                view
                  ? null
                  : (row) => {
                      setModalToken(true);
                      setTokenID(row.trx_code);
                    }
              }
              pageCount={dataBooking.data?.total_page}
              totalItems={dataBooking.data?.total_item}
              loading={loading}
              filters={
                role == "sa" ? 
                [
                  {
                    label: "Building: ",
                    value: building ? buildingLabel : "All",
                    hidex: building === "",
                    delete: () => setBuilding(""),
                    component: (toggleModal) => (
                      <>
                        <Input
                          label="Search Building"
                          compact
                          icon={<FiSearch />}
                          inputValue={search}
                          setInputValue={setSearch}
                        />
                        <Filter
                          data={buildingList}
                          onClick={(el) => {
                            if (!el.value) {
                              setLimit(limit + el.restTotal);
                              return;
                            }
                            setBuilding(el.value);
                            setBuildingLabel(el.label);
                            setLimit(5);
                            toggleModal(false);
                          }}
                          onClickAll={() => {
                            setBuilding("");
                            setBuildingLabel("");
                            setLimit(5);
                            toggleModal(false);
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    label: (
                      <div>
                        {stat ? (
                          <div>
                            Status: <b>{statLabel}</b>
                          </div>
                        ) : (
                          <div>
                            Status: <b>All</b>
                          </div>
                        )}
                      </div>
                    ),
                    hidex: stat === "",
                    delete: () => setStat(""),
                    component: (toggleModal) => (
                      <>
                        <Filter
                          data={listBookingStat}
                          onClick={(el) => {
                            setStat(el.value);
                            setStatLabel(el.label);
                            toggleModal(false);
                          }}
                          onClickAll={() => {
                            setStat("");
                            setStatLabel("");
                            toggleModal(false);
                          }}
                        />
                      </>
                    ),
                  },
                ]:[
                  {
                    label: (
                      <p>
                        {stat ? (
                          <div>
                            Status: <b>{statLabel}</b>
                          </div>
                        ) : (
                          <div>
                            Status: <b>All</b>
                          </div>
                        )}
                      </p>
                    ),
                    hidex: stat === "",
                    delete: () => setStat(""),
                    component: (toggleModal) => (
                      <>
                        <Filter
                          data={listBookingStat}
                          onClick={(el) => {
                            setStat(el.value);
                            setStatLabel(el.label);
                            toggleModal(false);
                          }}
                          onClickAll={() => {
                            setStat("");
                            setStatLabel("");
                            toggleModal(false);
                          }}
                        />
                      </>
                    ),
                  },
                ]
              }
            />,
            <Table
              columns={unitMeterNumberColumns}
              data={dataMeter.data?.items || []}
              fetchData={useCallback(
                (page, limit, searchItem) => {
                  role !== "sa" ? setBuilding(auth.building_id) : setBuilding(building);
                  setLoading(true);
                  dispatch(
                    get(
                      endpointManagement +
                        "/admin/building-unit-meter" +
                        "?building=" +
                        building +
                        "&search=" +
                        searchItem +
                        "&limit=" +
                        limit +
                        "&page=" +
                        (page + 1),
                      (res) => {
                        console.log(JSON.stringify(res.data))
                        setDataMeter(res.data);
                      }
                    ),
                    setLoading(false)
                  );
                },
                [dispatch, building, toggler]
              )}
              loading={loading}
              onClickEdit={
                view
                  ? null
                  : (row) => {
                    setMeterNumber(row.meter_number);
                    setNumber(row.number);
                    setUnit(row.unit_id);
                    setModalUpdateMeter(true);
                    }
              }
              actions={
                view
                  ? null
                  : [
                      <Button
                        key="Add Meter"
                        label="Add Meter"
                        icon={<FiPlus />}
                        onClick={() => {
                          setModalMeter(true)
                        }}
                      />,
                    ]
              }
              pageCount={dataMeter.data?.total_pages}
              totalItems={dataMeter.data?.total_items}
              filters={
                role == "sa" ?
                [
                  {
                    label: "Building: ",
                    value: building ? buildingLabel : "All",
                    hidex: building === "",
                    delete: () => setBuilding(""),
                    component: (toggleModal) => (
                      <>
                        <Input
                          label="Search Building"
                          compact
                          icon={<FiSearch />}
                          inputValue={search}
                          setInputValue={setSearch}
                        />
                        <Filter
                          data={buildingList}
                          onClick={(el) => {
                            if (!el.value) {
                              setLimit(limit + el.restTotal);
                              return;
                            }
                            setBuilding(el.value);
                            setBuildingLabel(el.label);
                            setLimit(5);
                            toggleModal(false);
                          }}
                          onClickAll={() => {
                            setBuilding("");
                            setBuildingLabel("");
                            setLimit(5);
                            toggleModal(false);
                          }}
                        />
                      </>
                    ),
                  },
                ] : []
              }
            />,
          ]}
        />
      </div>
    </>
  );
}

export default Component;
