import React, { useState } from "react";
import ModalWizard from "../../../components/ModalWizard";
import { FiDisc, FiCircle, FiCheckCircle } from "react-icons/fi";
import Button from "../../../components/Button";

const ModalWizardBillingCategory = ({ openWizard, setOpenWizard }) => {
  const [wizardStep, setWizardStep] = useState(1);
  return (
    <ModalWizard
      width="1080px"
      padding="0px"
      margin="90px"
      className="special_modal"
      isOpen={openWizard}
      disableFooter
      disableHeader
      bodyheight={wizardStep === [2, 3, 4, 5] ? "480px" : ""}
      toggle={() => setOpenWizard(false)}
    >
      {wizardStep === 1 && (
        <>
          <div className="row">
            <div className="col-4">
              <img
                src={require("../../../assets/quick_start.jpg")}
                width="280px"
                height="480px"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              />
            </div>
            <div className="col-8">
              <div className="col">
                <div className="wizard-title">
                  Apa yang ingin kamu pelajari?
                </div>
                <div className="wizard-body">
                  Kamu dapat mempelajari berbagai fitur yang ada di menu ini.
                </div>
                <div className="wizard-body">
                  Silahkan pilih salah satu untuk melanjutkan.
                </div>
              </div>
              <div className="row" style={{ marginRight: "20px" }}>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(2);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Upload Bulk
                        </div>
                        <div className="wizard-body-container-bil">
                          Upload billing resident <br /> secara massal.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(6);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Download Data
                        </div>
                        <div className="wizard-body-container-bil">
                          Unduh semua data billing category <br />
                          dalam bentuk csv.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col"> </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Start of Wizard Upload Bulk Billing */}
      {wizardStep === 2 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>+ Upload Bulk</b>”. Lalu klik tombol “
              <b>Download Template</b>”.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 3 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Buka file <b>Template Upload Bulk Billing</b> yang sudah kamu
              download tadi. Lalu isi data billing sesuai dengan tabel yang
              sudah disediakan.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(2);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 4 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah mengisi data billing di template tadi, upload kembali file
              tersebut lalu submit.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(5);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 5 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Upload Bulk Billing, kamu dapat
                  melihat detail billing tersebut di Menu Billing &gt; Category.
                </li>
                <li>
                  Kamu dapat menambahkan billing tambahan di detail billing jika
                  diperlukan.
                </li>
                <li>
                  Kamu dapat merelease billing tersebut kepada resident yang
                  bersangkutan.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Upload Bulk Billing */}

      {/* Start of Wizard Download Billing Category */}
      {wizardStep === 6 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Download Billing Category
            </div>
          </div>
          <div className="row">
            <div className="col ml-4 mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Billing Category
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol <b>Download.csv</b>. Kamu dapat melihat semua
              informasi billing category lengkap dengan informasi resident
              tersebut.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/billing_category/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Download Billing Category */}
    </ModalWizard>
  );
};

export default ModalWizardBillingCategory;
