import React, { useState } from "react";
import ModalWizard from "../../../components/ModalWizard";
import { FiDisc, FiCircle, FiCheckCircle } from "react-icons/fi";
import Button from "../../../components/Button";

const ModalWizardBillingUnit = ({ openWizard, setOpenWizard }) => {
  const [wizardStep, setWizardStep] = useState(1);
  return (
    <ModalWizard
      width="1080px"
      padding="0px"
      margin="90px"
      className="special_modal"
      isOpen={openWizard}
      disableFooter
      disableHeader
      bodyheight={wizardStep == [2, 3, 4, 5] ? "480px" : ""}
      toggle={() => setOpenWizard(false)}
    >
      {wizardStep === 1 && (
        <>
          <div className="row">
            <div className="col-4">
              <img
                src={require("../../../assets/quick_start.jpg")}
                width="280px"
                height="480px"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              />
            </div>
            <div className="col-8">
              <div className="col">
                <div className="wizard-title">
                  Apa yang ingin kamu pelajari?
                </div>
                <div className="wizard-body">
                  Kamu dapat mempelajari berbagai fitur yang ada di menu ini.
                </div>
                <div className="wizard-body">
                  Silahkan pilih salah satu untuk melanjutkan.
                </div>
              </div>
              <div className="row" style={{ marginRight: "20px" }}>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(2);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Upload Bulk Billing
                        </div>
                        <div className="wizard-body-container-bil">
                          Upload billing resident <br /> secara massal.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(6);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Upload Bulk Set As Paid
                        </div>
                        <div className="wizard-body-container-bil">
                          Atur status billing menjadi <br /> Paid secara massal.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(10);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Release All
                        </div>
                        <div className="wizard-body-container-bil">
                          Release semua billing kepada <br /> resident.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(14);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Release Selected
                        </div>
                        <div className="wizard-body-container-bil">
                          Release beberapa billing yang <br /> sudah dipilih
                          kepada resident.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(17);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Set As Paid Selected
                        </div>
                        <div className="wizard-body-container-bil">
                          Atur status beberapa billing yang <br /> sudah dipilih
                          menjadi Paid.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(20);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Download Billing Unit
                        </div>
                        <div className="wizard-body-container-bil">
                          Unduh semua data billing unit <br /> dalam file csv.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Start of Wizard Upload Bulk Billing */}
      {wizardStep === 2 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>+ Upload Bulk</b>”. Lalu klik tombol “
              <b>Download Template</b>”.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 3 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Buka file <b>Template Upload Bulk Billing</b> yang sudah kamu
              download tadi. Lalu isi data billing sesuai dengan tabel yang
              sudah disediakan.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(2);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 4 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah mengisi data billing di template tadi, upload kembali file
              tersebut lalu submit.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            ></div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "330px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(5);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 5 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Billing
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Upload Bulk Billing, kamu dapat
                  melihat detail billing tersebut di Menu Billing &gt; Unit.
                </li>
                <li>
                  Kamu dapat menambahkan billing tambahan di detail billing jika
                  diperlukan.
                </li>
                <li>
                  Kamu dapat merelease billing tersebut kepada resident yang
                  bersangkutan.
                </li>
                <li>
                  Kamu dapat melakukan set as paid jika ada tagihan yang belum
                  ter-paid oleh sistem.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Upload Bulk Billing */}

      {/* Start of Wizard Upload Bulk Set As paid */}
      {wizardStep === 6 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Set As Paid
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Isi Form Template Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>+ Upload Bulk Set As Paid</b>”. Lalu klik tombol “
              <b>Download Template</b>”.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/bulk_set_as_paid/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(7);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 7 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Set As Paid
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Template Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Buka file <b>Template Set As Paid</b> yang sudah kamu download
              tadi. Lalu isi data sesuai dengan tabel yang sudah disediakan.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/bulk_set_as_paid/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(8);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(6);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 8 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Set As Paid
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Template Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah mengisi data billing yang akan di set as paid pada
              template tadi, upload kembali file tersebut lalu submit.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/bulk_set_as_paid/step_3.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(9);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(7);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 9 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Upload Bulk Set As Paid
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Template Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Upload Bulk Set As Paid, kamu akan
                  mendapatkan notifikasi sukses upload.
                </li>
                <li>
                  Kamu dapat melihat status data yang kamu upload tadi pada file{" "}
                  <b>data.csv</b> yang otomatis terdownload setelah kamu
                  melakukan <br />
                  upload bulk set as paid.
                </li>
                <li>
                  Dalam data.csv tersebut ada kolom keterangan <b>status</b>{" "}
                  yang menunjukkan data yang kamu upload apakah berhasil atau
                  tidak.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(8);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Upload Bulk Set As paid */}

      {/* Start of Wizard Release All */}
      {wizardStep === 10 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Release All</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Klik Release All
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Pilih Bulan dan Tahun
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>Release All</b>”. Lalu akan muncul popup untuk
              mengatur data billing yang akan kamu release.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/release_all/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(11);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 11 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Release All</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Klik Release All
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Bulan dan Tahun
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pilih bulan dan tahun untuk merelease billing yang ada di periode
              tersebut.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/release_all/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(12);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(10);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 12 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Release All</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Klik Release All
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Bulan dan Tahun
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pilih <b>Now</b> untuk langsung release, atau <b>Other</b> untuk
              mengatur jadwal release billing. Lalu pilih release dengan foto
              catat meter atau tidak.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/release_all/step_3.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(13);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(11);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 13 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Release All</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Klik Release All
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Bulan dan Tahun
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Release All, kamu akan mendapatkan
                  pemberitahuan jumlah billing yang sukses direlease.
                </li>
                <li>
                  Resident akan mendapatkan push notifikasi mengenai tagihan
                  billing pada akun Yipy mereka.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(12);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Release All */}

      {/* Start of Wizard Release Selected */}
      {wizardStep === 14 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Release Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pilih bulan dan tahun untuk merelease billing yang ada di periode
              tersebut.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/release_selected/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(15);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 15 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Release Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pilih <b>Now</b> untuk langsung release, atau <b>Other</b> untuk
              mengatur jadwal release billing. Lalu pilih release dengan foto
              catat meter atau tidak.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/release_selected/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(16);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(14);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 16 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Release Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Atur Waktu Release
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Release All, kamu akan mendapatkan
                  pemberitahuan jumlah billing yang sukses direlease.
                </li>
                <li>
                  Resident akan mendapatkan push notifikasi mengenai tagihan
                  billing pada akun Yipy mereka.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(15);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Release Selected */}

      {/* Start of Wizard Set As Paid Selected */}
      {wizardStep === 17 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Set As Paid Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Konfirmasi Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pilih billing, lalu klik tombol <b>Set As Paid Selected</b>.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/set_as_paid_selection/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(18);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 18 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Set As Paid Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Konfirmasi Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah itu akan muncul popup konfirmasi. Klik <b>Yes</b> jika
              kamu yakin untuk melakukan set as paid atau <b>No</b> untuk
              membatalkan.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/set_as_paid_selection/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(19);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(17);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 19 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Set As Paid Selected
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Pilih Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Konfirmasi Set As Paid
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Akan ada pemberitahuan di halaman tersebut apakah set as paid
                  berhasil atau gagal.
                </li>
                <li>
                  Status billing tersebut akan berubah dari Unpaid menjadi Paid.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginTop: "215px",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(18);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Set As Paid Selected */}

      {/* Start of Wizard Download Billing Unit */}
      {wizardStep === 20 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Download Billing Unit
            </div>
          </div>
          <div className="row">
            <div className="col ml-4 mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Billing Unit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol <b>Download Billing Unit.csv</b>. Kamu dapat melihat
              semua informasi billing lengkap dengan informasi resident
              tersebut.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/download_billing_unit/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Download Billing Unit */}
    </ModalWizard>
  );
};

export default ModalWizardBillingUnit;
