import React, { useCallback, useState } from "react";
import ModalWizard from "../../../components/ModalWizard";
import Button from "../../../components/Button";
import { FiCheckCircle, FiDisc } from "react-icons/fi";

const ModalWizardResident = ({ openWizard, setOpenWizard }) => {
  const [wizardStep, setWizardStep] = useState(1);

  const wizardBackFunction = useCallback(
    () => setWizardStep(wizardStep != 1 ? wizardStep - 1 : wizardStep),
    [wizardStep]
  );

  return (
    <ModalWizard
      width="1080px"
      padding="0px"
      margin="90px"
      className="special_modal"
      isOpen={openWizard}
      disableFooter
      disableHeader
      toggle={() => setOpenWizard(false)}
    >
      {wizardStep === 1 && (
        <>
          <div className="row">
            <div className="col-4">
              <img
                src={require("../../../assets/quick_start.jpg")}
                width="280px"
                height="480px"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              />
            </div>
            <div className="col-8">
              <div className="wizard-title">Apa yang ingin kamu pelajari?</div>
              <div className="wizard-body">
                Kamu dapat mempelajari berbagai fitur yang ada di menu ini.
              </div>
              <div className="wizard-body">
                Silahkan pilih salah satu untuk melanjutkan.
              </div>
              {/* <div className="Container-wizard border-wizard">Resident</div> */}
              <div className="col">
                <div
                  className="Container-wizard border-wizard d-flex flex-column"
                  onClick={() => {
                    setWizardStep(2);
                  }}
                >
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="w-auto">
                        <img
                          src={require("../../../assets/Resident_Wizard.jpg")}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-nowrap ml-4 wizard-title-container">
                        Add Resident
                      </div>
                      <div className="text-nowrap ml-4 wizard-body-container">
                        Tambah resident dengan input data satu per satu.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="Container-wizard border-wizard d-flex flex-column"
                  onClick={() => {
                    setWizardStep(8);
                  }}
                >
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="w-auto">
                        <img
                          src={require("../../../assets/Resident_Wizard_Group.jpg")}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="text-nowrap ml-4 wizard-title-container">
                        Add Resident Bulk
                      </div>
                      <div className="text-nowrap ml-4 wizard-body-container">
                        Tambah resident dengan input data dalam jumlah banyak.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Start of Wizard Add Resident */}
      {wizardStep === 2 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>+ Add Resident</b>”. Lalu kamu dapat memasukkan
              email atau no. HP saja atau memasukkan keduanya.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 3 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Masukkan nama depan dan nama belakang pada form yang tersedia.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(2);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 4 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Isi data kewarganegaraan, tempat dan tanggal lahir, jenis kelamin,
              status pernikahan dan pekerjaan.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/step_3.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(5);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 5 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Masukkan alamat rumah dengan lengkap.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/step_4.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(6);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 6 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Masukkan nama bank, nomor rekening, dan nama pemilik rekening.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/step_5.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(7);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(5);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 7 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">Add Resident</div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Check Email/No. HP
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Full Name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Address
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Bank Account
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Add Resident, kamu dapat melihat
                  detail informasi resident tersebut di Menu Resident.
                </li>
                <li>Kamu dapat menambahkan unit pada resident tersebut.</li>
                <li>
                  Kamu dapat mengedit detail informasi resident tersebut jika
                  ada kesalahan saat input data.
                </li>
                <li>
                  Kamu dapat menghapus resident jika resident tersebut sudah
                  tidak berada di building kamu.
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(6);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Add Resident */}
      {/* Start of Wizard Add Resident Bulk */}
      {wizardStep === 8 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Add Resident Bulk
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Isi Form Data Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Klik tombol “<b>+ Add Resident</b>”. Lalu klik tombol “Download
              Template”.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/uploadBulkRes/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(9);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 9 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Add Resident Bulk
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Buka file resident template yang sudah kamu download tadi. Lalu
              isi data resident sesuai dengan tabel yang sudah disediakan.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/uploadBulkRes/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(10);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(8);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 10 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Add Resident Bulk
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah mengisi data resident di template tadi, upload kembali
              file tersebut lalu submit.{" "}
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/uploadBulkRes/step_3.gif")}
                width="100%"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(11);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(9);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 11 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Add Resident Bulk
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Template
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Isi Form Data Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Upload Bulk File
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Information
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              <b>Apa yang dapat dilakukan selanjutnya?</b>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body mt-4">
              <ul>
                <li>
                  Setelah berhasil melakukan Add Resident Bulk, kamu dapat
                  melihat detail informasi resident tersebut di Menu Resident.
                </li>
                <li>
                  Kamu dapat menambahkan unit tambahan jika diperlukan pada
                  resident tersebut.
                </li>
                <li>
                  Kamu dapat mengedit detail informasi resident tersebut jika
                  ada kesalahan saat input data.
                </li>
                <li>
                  Kamu dapat menghapus resident jika resident tersebut sudah
                  tidak berada di building kamu.
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div
              className="col text-right"
              style={{ marginBottom: "10px", marginRight: "5px" }}
            >
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(10);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Add Resident Bulk */}
    </ModalWizard>
  );
};

export default ModalWizardResident;
