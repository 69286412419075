import React, { useState } from "react";
import ModalWizard from "../../../components/ModalWizard";
import { FiDisc, FiCircle, FiCheckCircle } from "react-icons/fi";
import Button from "../../../components/Button";

const ModalWizardBilling = ({ openWizard, setOpenWizard }) => {
  const [wizardStep, setWizardStep] = useState(1);
  return (
    <ModalWizard
      width="1080px"
      padding="0px"
      margin="90px"
      className="special_modal"
      isOpen={openWizard}
      disableFooter
      disableHeader
      bodyheight={wizardStep === [2, 3, 4, 5] ? "480px" : ""}
      toggle={() => setOpenWizard(false)}
    >
      {wizardStep === 1 && (
        <>
          <div className="row">
            <div className="col-4">
              <img
                src={require("../../../assets/quick_start.jpg")}
                width="280px"
                height="480px"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              />
            </div>
            <div className="col-8">
              <div className="col">
                <div className="wizard-title">
                  Apa yang ingin kamu pelajari?
                </div>
                <div className="wizard-body">
                  Kamu dapat mempelajari berbagai fitur yang ada di menu ini.
                </div>
                <div className="wizard-body">
                  Silahkan pilih salah satu untuk melanjutkan.
                </div>
              </div>
              <div className="row" style={{ marginRight: "20px" }}>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(2);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Apa Billing Settlement?
                        </div>
                        <div className="wizard-body-container-bil">
                          Hal apa saja yang dapat kamu lihat <br />
                          pada halaman billing settlement.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(3);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Detail Billing Settlement
                        </div>
                        <div className="wizard-body-container-bil">
                          Lihat semua data billing settlement <br />
                          resident.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="Container-wizard-bil border-wizard-bil d-flex flex-column"
                    onClick={() => {
                      setWizardStep(5);
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col">
                        <div className="text-nowrap wizard-title-container-bil">
                          Download Data
                        </div>
                        <div className="wizard-body-container-bil">
                          Unduh semua data billing settlement <br />
                          dalam bentuk csv.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Start of Wizard Billing Settlement */}
      {wizardStep === 2 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Apa Billing Settlement?
            </div>
          </div>
          <div className="row">
            <div className="col ml-4 mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Fungsi Billing Settlement
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pada halaman Billing Settlement, kamu dapat melihat jumlah billing
              dengan status Unsettled maupun Settled.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/billing_settlement/step_1.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Billing Settlement */}

      {/* Start of Wizard Detail Billing Settlement */}
      {wizardStep === 3 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Detail Billing Settlement
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Informasi Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard-inactive d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon-inactive">
                      <FiCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body-inactive">
                      Informasi Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pada halaman Billing Settlement, pilih salah satu data billing.
              Lalu kamu bisa melihat informasi resident yang bersangkutan.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/billing_settlement/step_2.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Next"
                onClick={() => {
                  setWizardStep(4);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {wizardStep === 4 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Detail Billing Settlement
            </div>
          </div>
          <div className="row">
            <div className="col ml-4">
              <div className="Container-step-wizard border-step-wizard-done d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto">
                      <FiCheckCircle />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Informasi Resident
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Informasi Billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Setelah masuk ke halaman Paid Details, scroll hingga bagian paling
              bawah.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/billing_settlement/step_3.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(3);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Detail Billing Settlement */}

      {/* Start of Wizard Download Data Settlement */}
      {wizardStep === 5 && (
        <>
          <div className="row">
            <div className="col wizard-header ml-4 mb-4 mt-4">
              Download Data Settlement
            </div>
          </div>
          <div className="row">
            <div className="col ml-4 mr-4">
              <div className="Container-step-wizard border-step-wizard d-flex flex-column">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="w-auto wizard-step-body-icon">
                      <FiDisc />
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-nowrap ml-1 wizard-step-body">
                      Download Data Billing Settlement
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col wizard-body ml-4 mt-4">
              Pada halaman Billing Settlement, click tombol <b>Download.csv</b>{" "}
              pada bagian kanan halaman.
            </div>
          </div>
          <div className="col-auto mt-5 mb-5">
            <div
              className="w-auto text-center"
              style={{ animation: "fadeIn 3s" }}
            >
              <img
                src={require("../../../assets/wizard/billing/billing_settlement/step_4.gif")}
                width="100%"
              />
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <div className="col text-right">
              <Button
                className="float-right"
                key="?"
                label="Done"
                onClick={() => {
                  setWizardStep(1);
                  setOpenWizard(false);
                }}
              />
              <Button
                className="float-right"
                color="Secondary"
                key="?"
                label="Previous"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
              <Button
                color="Secondary"
                className="float-left"
                key="?"
                label="Cancel"
                onClick={() => {
                  setWizardStep(1);
                }}
              />
            </div>
          </div>
        </>
      )}
      {/* End of Wizard Download Data Settlement */}
    </ModalWizard>
  );
};

export default ModalWizardBilling;
