import { createSlice } from "@reduxjs/toolkit";
import { endpointTransaction, endpointManagement } from '../../settings';
import { get, post, put, del, setInfo, getFile } from '../slice';

export const slice = createSlice({
  name: "tokenthera",
  initialState: {
    loading: false,
    items: [],
    selected: {},
    total_items: 0,
    total_pages: 1,
    page: 1,
    range: 10,
    refreshToggle: true,
  },
  reducers: {
    startAsync: (state) => {
      state.loading = true;
    },
    stopAsync: (state) => {
      state.loading = false;
    },
    setData: (state, action) => {
      const data = action.payload;

      state.items = data.items;
      state.total_items = data.filtered_item;
      state.total_pages = data.filtered_page;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    refresh: (state) => {
      state.refreshToggle = !state.refreshToggle;
    },
  },
});


export const editToken = ( token, refCode, id) => dispatch => {
  dispatch(startAsync());

  dispatch(put(endpointTransaction + '/admin/thera/token', {
    token_code: token,
    token_ref_code: refCode,
    trx_code: id
  }, 
    res => {
      dispatch(refresh());
      
      dispatch(setInfo({
        color: 'success',
        message: 'Token has been updated.'
      }));

      dispatch(stopAsync());
    },
    err => {
      dispatch(stopAsync());
    }))
}

export const createMeter = ( unitId, meterNumber) => dispatch => {
  dispatch(startAsync());

  dispatch(post(endpointManagement + '/admin/building-unit-meter', {
    unit_id: parseInt(unitId),
    meter_number: meterNumber
  }, 
    res => {
      
      dispatch(setInfo({
        color: 'success',
        message: 'Token has been updated.'
      }));

      dispatch(stopAsync());
    },
    err => {
      dispatch(stopAsync());
    }))
}

export const updateMeter = ( unitId, meterNumber) => dispatch => {
  dispatch(startAsync());

  dispatch(put(endpointManagement + '/admin/building-unit-meter/update', {
    unit_id: parseInt(unitId),
    meter_number: meterNumber
  }, 
    res => {
      
      dispatch(setInfo({
        color: 'success',
        message: 'Token has been updated.'
      }));

      dispatch(stopAsync());
    },
    err => {
      dispatch(stopAsync());
    }))
}

export const {
  startAsync,
  stopAsync,
  setData,
  setSelected,
  refresh,
} = slice.actions;

export default slice.reducer;
